import React from "react";
import {Button, Stack} from "@mui/material";
import {firebase} from "../../../firebase/clientApp";
import SpinWheel from "./SpinWheel/SpinWheel";

export const GiraLaRuota = ({elementPlaying, pinned}) => {

    return(<Stack id={'zioPera'} alignItems={'center'} height={'max-content'}>
            <SpinWheel elementPlaying={elementPlaying} pinned={pinned}/>
        </Stack>
  )
}