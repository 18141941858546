import React, {useEffect, useMemo, useState} from 'react';
import {
    alpha,
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Fade,
    Grow,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Slide,
    Stack,
    Typography
} from "@mui/material";
import arrow from "./arrow-53.png";
import {useNavigate} from "react-router-dom";
import {FiberManualRecord} from "@mui/icons-material";
import pleshDark from "../../../../theme/pleshDark";

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export const Transition = React.forwardRef(function Transition(
    props,
    ref
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

let number = Math.ceil(Math.random() * 1000);

let startX;
let startY;

let endX;
let endY;
const delta = 5;

const SpinWheel = ({elementPlaying, pinned}) => {
    useEffect(() => {
        //localStorage.clear()
        localStorage.setItem('numSpin', '0')
    }, [])

    const navigate = useNavigate()

    let numSpin = parseInt(localStorage.getItem('numSpin')) || 0

    const [winnerSegment, setWinnerSegment] = useState(null)
    const [appear, setAppear] = useState(false)
    const [spinning, setSpinning] = useState(false)

    useEffect(() => {
        setAppear(true)
    }, [])

    let [container, setContainer] = useState(null)

    useEffect(() => {
        if (document.querySelector(".container")) {
            setContainer(document.querySelector(".container"));
        }
    }, [document])

    useEffect(() => {
        if (container) {
            container.addEventListener('transitionend', (event) => {
                setSpinning(false)
                //console.log("numSpin:", numSpin)
                //console.log("event:", event)
                console.log('L\'animazione di rotazione è terminata.');
                if (numSpin > 2) {
                    /** Vince quello con la Y più bassa apparentemente */
                    let winner, minimumY
                    for (const segment of buoniPropositi) {
                        const segY = document.querySelector(`.${segment.id}`).getBoundingClientRect().y
                        //console.log("segY:", segY)
                        if (!minimumY) {
                            minimumY = segY
                            winner = segment
                        } else {
                            if (segY < minimumY) {
                                minimumY = segY
                                winner = segment
                            }
                        }
                    }
                    //console.log("handleSpin > winner:", winner.label)

                    if (winner) {
                        setWinnerSegment({...winner, interactionType: 'spin'})//window.alert(winner?.label)
                    }
                }

                localStorage.setItem('numSpin', (++numSpin).toString())
            });
        }
    }, [container])

    function handleSpin() {
        container.style.transform = "rotate(" + number + "deg)";
        container.style.transitionTimingFunction = 'ease-in-out'
        container.style.transition = 'opacity 1500ms ease-in-out 0ms, transform 1599ms 10ms'
        number += Math.ceil(Math.random() * 2000);
        //console.log("number:", number)
        setSpinning(true)
    }

    function handleOpenDialog(event) {
        event.stopPropagation()
        setWinnerSegment({
            ...segmentsMap[event.target.className || event.target.offsetParent.className],
            interactionType: 'touch'
        })

        //localStorage.setItem('numSpin', (7).toString())
        localStorage.setItem('numSpin', (++numSpin).toString())
    }

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        console.log("winnerSegment:",winnerSegment)
        if (winnerSegment) {
            console.log("winner:", winnerSegment.id)
            setTimeout(() => {
                //getRandomDescription()
                handleClickOpen()
            }, 900)
        }
    }, [winnerSegment])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        //if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            /*if (numSpin >= 6) {
                //if(numSpin >= 20) {
                setTimeout(() => {
                    navigate('/thank-you-page')
                }, 200)
            }*/

            setOpen(false);
        //}
    };

    function handleCloseSpinWheel() {
        setTimeout(() => {
            navigate('/thank-you-page')
        }, 200)
    }

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setWinnerSegment(null)
            }, 500)
        }
    }, [open])

    const [label, descr] = useMemo(() => {
        return [winnerSegment?.descriptions[0]?.label, winnerSegment?.descriptions[0]?.body]
    }, [winnerSegment])

    return (
        <Box>
            {/*<img id={'arrow-visual'} src={arrow}
                 onClick={!spinning ? handleSpin : null}
                 style={{
                     position: 'absolute',
                     top: 310,
                     right: 310,
                     maxWidth: '160px',
                     overflow: 'hidden',
                     filter: 'brightness(0) invert(1) opacity(0.2)',
                     transform: 'rotate(349deg)'
                 }}
            />*/}
            {/*<Stack p={1.5} spacing={1} style={{
                position: 'absolute',
                top: 20,
                right: 20,
                maxWidth: '360px',
                background: alpha(pleshDark.palette.primary.main, 0.4),
                borderRadius: '5%'
            }}>
                {
                    buoniPropositi.map((segment, i) => (
                        <Stack key={i} direction={'row'} spacing={1} alignItems={'center'}>
                            <Avatar sx={{
                                background: pleshDark.palette.primary.main,
                                width: 24,
                                height: 24
                            }}>
                                <FiberManualRecord sx={{
                                    //background: segment.backgroundColor,
                                    color: segment.backgroundColor,
                                    fontSize: '24px'
                                }} fontSize={'large'}/>
                            </Avatar>
                            <Typography variant={'body1'} fontWeight={'bold'}>{segment.label}</Typography>
                        </Stack>)
                    )
                }
            </Stack>*/}
            <Fade in={appear} timeout={200}>
                <button id="spin" onClick={!spinning ? handleSpin : null}
                    style={{left: pinned ? '325px' : '40px'}}
                >
                    FBN
                </button>
            </Fade>
            <Slide in={appear} timeout={350}>
                <span className={'arrow-wrap'}
                      style={{left: pinned ? '220px' : '-70px'}}
                >
                  <span className="arrow"/>
                </span>
            </Slide>
            <Grow in={appear} timeout={500}>
                <div className="container" style={{width: '650px', height: '650px',
                    //transform: 'scale(0.5)'
                }}
                     onTouchStart={(event) => {
                         startX = event.touches[0]?.screenX;
                         startY = event.touches[0]?.screenY;

                         endX = event.touches[0]?.screenX;
                         endY = event.touches[0]?.screenY;
                     }}

                     onTouchMove={(event) => {
                         endX = event.touches[0]?.screenX;
                         endY = event.touches[0]?.screenY;
                     }}

                     onTouchEnd={(event) => {
                         const diffX = Math.abs(endX - startX);
                         const diffY = Math.abs(endY - startY);

                         if (!spinning) {
                             if (!(diffX > delta && diffY > delta)) {
                                 handleOpenDialog(event)
                             } else {
                                 handleSpin()
                             }
                         }
                     }}
                >
                    {
                        buoniPropositi.map((seg, i) => (
                            <div key={i} className={seg.id}
                                 style={{
                                     left: i === 0 ? '50%' : '195px',
                                     background: seg.id === winnerSegment?.id ? 'transparent' : seg.backgroundColor,
                                     fontSize: seg.id === winnerSegment?.id ? '1.5rem' : '',
                                 }}
                            >
                                {/*<img src={require(`./sector-icons/${seg?.id}.png`)}
                                      width={seg.id === winnerSegment?.id ? '120px' : '90px'}
                                      style={{
                                          position: 'absolute', top: 10, left: -5,
                                          transform: 'rotate(-15deg)',
                                          filter: seg.id === winnerSegment?.id ? ''
                                              : 'brightness(0) invert(1) opacity(0.5)',
                                          opacity: 0.8,
                                          userSelect: 'none'
                                      }}
                                />*/}
                                <p style={{wordBreak: 'auto-phrase', maxWidth: '180px'}}>
                                    {seg.label}
                                </p>
                            </div>
                            /*<div key={i} className={seg.id} style={{left: i === 0 ? '50%' : '196px'}}><p>{seg.label}</p>
                            </div>*/
                        ))
                    }
                </div>
            </Grow>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                transitionDuration={{enter: 900, exit: 400}}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <ListItem sx={{color: pleshDark.palette.primary.main}}>
                    {/*<ListItemAvatar>
                        {winnerSegment &&
                            <Avatar sx={{width: 48, height: 48, background: alpha('#5187bc', 0.2), padding: 0.5}}
                                    src={require(`./sector-icons/${winnerSegment?.id}.png`)}>
                            </Avatar>}
                    </ListItemAvatar>*/}
                    <ListItemText primary={<Typography sx={{color: 'white'}} variant={'h5'} fontWeight={'bold'}>{label || ''}</Typography>}
                                  /*secondary={winnerSegment?.label}*//>
                </ListItem>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {descr || ''}
                    </DialogContentText>
                    <Typography fontStyle={'italic'} fontSize={13} sx={{color: 'white'}} >
                        FBN 2024
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {/*numSpin < 6 &&
                        <Button variant={'home-question'} onClick={() => {
                            handleClose()
                            setTimeout(() => {
                                handleSpin()
                            }, 200)
                        }}>Gira di nuovo!</Button>*/}
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default SpinWheel;

const buoniPropositi = [
    {
        id: 'drink',
        backgroundColor: '#FF568C',
        label: 'Offri un drink al vicino',
        descriptions: [
            {
                "settore": "Offri un drink",
                "label": "Ora offri un drink al tuo vicino di posto",
                "body": <span>
                </span>
            },
        ]
    },
    {
        id: 'primoChePassa',
        backgroundColor: '#fdafc8',
        label: 'Chiedi al primo che passa la sua notizia preferita',
        descriptions: [
            {
                "settore": "Chiedi al primo che passa",
                "label": "Chiedi alla prima persona che incontri quale è stata la sua notizia preferita dell'evento",
                "body": <span>
                </span>
            },
        ]
    },
    {
        id: 'chiama',
        backgroundColor: '#9f5cff',
        label: 'Chiama chi ti manca',
        descriptions: [
            {
                "settore": "Chiama chi ti manca",
                "label": "Chiama qualcuno che ti manca e diglielo ora",
                "body": <span>
                </span>
            },
        ]
    },
    {
        id: 'fotoSpeaker',
        backgroundColor: '#CAA6FC',
        label: 'Foto con gli speaker',
        descriptions: [
            {
                "settore": "Foto con gli speaker",
                "label": "Cerca gli speaker rimasti in evento per farti una foto con loro",
                "body": <span>
                </span>
            },
        ]
    },
    {
        id: 'seguiFbn',
        backgroundColor: '#7b1aff',
        label: 'Segui FBN su IG!',
        descriptions: [
            {
                "settore": "Segui FBN",
                "label": "Inizia a seguire su Instagram la pagina Festival Buone Notizie",
                "body": <span>
                    <a href={""} target={"_blank"} style={{fontWeight: 'bold', color: 'white'}}> Vedi Instagram</a>
                </span>
            },
        ]
    }
]


const segments = [
    {
        id: 'agroAnima',
        backgroundColor: '#fab6b0',
        label: 'Agroalimentare Animale',
        descriptions: [
            {
                "settore": "Agroalimentare Animale",
                "label": "Precision farming potenziata",
                "body": <span>
  <p>
    L’agricoltura di precisione supporta gli agricoltori nel prendere decisioni ottimali per la produzione della mangimistica vegetale.
    Attraverso l’analisi di informazioni provenienti da diversi sistemi, è possibile prevedere la gestione delle acque, la rotazione dei raccolti,
    le tempistiche di semina e raccolto, nonché la gestione dei fertilizzanti.
  </p>
  <p>
    L’applicazione di soluzioni di questo tipo rende possibile ridurre sprechi di acqua e assicurare una crescita sana delle piante.
    La pianificazione accurata della rotazione dei raccolti aiuta a preservare la fertilità del suolo e a prevenire le malattie delle piante.
    L’utilizzo di dati climatici come la temperatura e il ciclo di crescita delle piante, infatti, consente di determinare il momento ideale per seminare
    e raccogliere, massimizzando così la resa dei raccolti.
  </p>
  <p>
    Infine, la gestione precisa dei fertilizzanti permette di fornire alle piante esattamente la quantità necessaria per una crescita ideale,
    riducendo gli sprechi e minimizzando l’impatto ambientale.
    L’introduzione di applicazioni di IA in questa specifica sottofase può apportare quindi diversi benefici economici.
    A seconda della condizione di partenza, l’agricoltura di precisione può portare a risparmi fino al 5-8% dei costi produttivi totali,
    grazie a un uso più efficiente delle risorse, l’ottimizzazione del tempo e del lavoro umano.
  </p>
</span>
            },
            {
                "settore": "Agroalimentare Animale",
                "label": "Analisi predittiva della domanda di mercato",
                "body": <span>
  <p>
    L’analisi della domanda di mercato nel settore agroalimentare animale si può avvalere di
    soluzioni di intelligenza artificiale predittiva che hanno la capacità di considerare un elevato
    numero di variabili differenti per prevedere l’andamento della domanda. Se propriamente
    integrati con infrastrutture di dati lungo la filiera, questi algoritmi permettono la creazione
    di sistemi di supporto che partendo dalle tendenze di mercato, i gusti dei consumatori e le
    esigenze dell’industria alimentare permettono di identificare i prodotti che avranno maggiore
    richiesta sul mercato.
  </p>
  <p>
    A sua volta, questo permette di prendere delle decisioni più informate nella definizione
    dell’allevamento e facilita la gestione delle coltivazioni di mangimi in modo più mirato grazie
    alle previsioni dell’andamento della domanda, aiutando gli agricoltori a ottimizzare le risorse,
    come il terreno, l’acqua e i fertilizzanti.
  </p>
  <p>
    Questi risultati possono portare dei vantaggi economici significativi, anche del 3-5% sui costi
    e, con l’ottimizzazione dei prezzi, di altrettanto sui ricavi.
  </p>
</span>
            },
        ]
    },
    {
        id: 'agroVeg',
        label: 'Agroalimentare Vegetale',
        backgroundColor: '#3f51b5',
        descriptions: [
            {
                label: "Precision farming potenziata",
                body: <span>
                    <p>L’agricoltura di precisione supporta gli agricoltori nel prendere decisioni ottimali per la coltivazione delle piante.</p>
                    <p>Attraverso l’analisi di informazioni provenienti da diversi sistemi, è possibile prevedere la gestione delle acque, la rotazione dei raccolti, le tempistiche di semina e raccolto, nonché la gestione dei fertilizzanti.</p>
                    <p>L’applicazione di soluzioni di questo tipo rende possibile ridurre sprechi di acqua e assicurare una crescita sana delle piante.</p>
                    <p>La pianificazione accurata della rotazione dei raccolti aiuta a preservare la fertilità del suolo e a prevenire le malattie delle piante.</p>
                    <p>L’utilizzo di dati climatici come la temperatura e il ciclo di crescita delle piante, infatti, consente di determinare il momento ideale per seminare e raccogliere, massimizzando così la resa dei raccolti.</p>
                    <p>Infine, la gestione precisa dei fertilizzanti permette di fornire alle piante esattamente la quantità necessaria per una crescita ideale, riducendo gli sprechi e minimizzando l’impatto ambientale.</p>
                    <p>L’introduzione di applicazioni di IA in questa specifica sottofase può apportare, quindi, diversi benefici economici.</p>
                    <p>A seconda della condizione di partenza, l’agricoltura di precisione può portare a risparmi fino al 5-8% dei costi produttivi totali, grazie a un uso più efficiente delle risorse, l’ottimizzazione del tempo e del lavoro manuale.</p>
                </span>
            },
            {
                label: "Analisi predittiva della domanda di mercato",
                body: <span>
                  <p>L’analisi della domanda di mercato nel settore agroalimentare vegetale si può avvalere di soluzioni di intelligenza artificiale predittiva che hanno la capacità di considerare un elevato numero di variabili differenti per prevedere l’andamento della domanda.</p>
                  <p>Questo può avere un notevole effetto anche sulla sottofase di coltivazione. Infatti questi algoritmi, se propriamente integrati con infrastrutture di dati lungo tutta la filiera, permettono la creazione di sistemi di supporto che aiutano nella selezione delle colture e nell’identificazione dei prodotti, favorendo la massimizzazione della redditività delle coltivazioni.</p>
                  <p>Le tendenze di mercato, i gusti dei consumatori e le esigenze dell’industria alimentare sono tra i dati principali considerati da questi algoritmi che, grazie a tali informazioni, rendono possibile scegliere le colture più richieste e redditizie.</p>
                  <p>Ciò consente agli agricoltori di differenziare i loro prodotti, ottenendo prezzi migliori e aumentando la propria competitività.</p>
                  <p>Inoltre, tali sistemi facilitano la gestione delle coltivazioni in modo più mirato grazie alle previsioni dell’andamento della domanda, aiutando gli agricoltori a ottimizzare le risorse, come il terreno, l’acqua e i fertilizzanti.</p>
                  <p>Questi risultati possono portare dei vantaggi economici significativi, anche del 3-5% sui costi e, con l’ottimizzazione dei prezzi, di altrettanto sui ricavi.</p>
                </span>
            },
            {
                label: "Monitoraggio intelligente delle condizioni metereologiche",
                body: <span>
                  <p>L’utilizzo di soluzioni di intelligenza artificiale permette di analizzare e monitorare con maggiore accuratezza le condizioni meteorologiche. Questo è reso possibile grazie alle capacità di modelli predittivi supportati da IA, che hanno una maggiore capacità di calcolo su numerosi tipi di variabili.</p>
                  <p>Le applicazioni esistenti dimostrano come questi strumenti supportino gli agricoltori nel prendere decisioni migliori riguardo alla semina, alla raccolta e ad altre attività agricole.</p>
                  <p>Per esempio, applicazioni di questo tipo rendono possibile decidere in maniera più informata quando è il momento migliore per seminare, tenendo conto delle condizioni meteorologiche favorevoli per la germinazione delle piante.</p>
                  <p>Un’altra applicazione possibile è rappresentata dalla pianificazione delle attività di raccolta considerando le previsioni di pioggia o di temperature estreme, riducendo perdite e danni alle colture.</p>
                  <p>Un più efficace monitoraggio delle condizioni metereologiche permette inoltre di adottare misure preventive, come l’irrigazione o la protezione delle colture, in caso di previsioni di siccità o di tempeste.</p>
                  <p>In termini economici, il monitoraggio delle condizioni climatiche, portando a un dispiego più efficiente di risorse, può migliorare a seconda dei contesti fino al 5% i costi produttivi.</p>
                </span>
            },
            {
                label: "Sistemi di controllo per robot agricoli",
                body: <span>
                  <p>I robot agricoli possono già svolgere una serie di attività, come la raccolta dei prodotti, il controllo della vegetazione infestante e le arature, con benefici in termini di efficienza e precisione. Grazie all’introduzione dell’intelligenza artificiale, questi macchinari possono prendere decisioni autonome in tempo reale, essendo equipaggiati da tecnologie di riconoscimento immagini e apprendimento per rinforzo che consente di ottimizzare il lavoro svolto.</p>
                  <p>Questo permette di automatizzare il raccolto e di evitare sprechi, essendo in grado di raccogliere esclusivamente i prodotti maturi. In altre applicazioni, sono stati utilizzati per rimuovere le erbacce senza danneggiare le colture circostanti, migliorando la salute delle piante e riducendo la dipendenza da pesticidi.</p>
                  <p>In casi sperimentali, l’automazione mediante robot è stata applicata nell’aratura, ottenendo una preparazione accurata del terreno per la semina. Alcuni studi affermano che tali benefici possano, in casi standard, avere un punto di caduta fino al 5% dei costi di produzione.</p>
                </span>
            }
        ]
    },
    {
        id: 'commercio',
        backgroundColor: '#f44336',
        label: 'Commercio ed e-commerce',
        descriptions: [
            {
                "settore": "Commercio ed e-commerce",
                "label": "Analisi automatizzata dei fattori di vendita",
                /*"body": "Il settore retail è caratterizzato da diversi casi sperimentali in cui l’intelligenza artificiale è stata adottata per una più approfondita analisi dei fattori di vendita. "
                    + "Partendo dai dati provenienti da diversi ambiti come gli attributi dei prodotti, i prezzi dei competitor, la distribuzione e la pubblicità, questa attività risulta fondamentale per capire quali siano i fattori che più influenzano le vendite. L’IA può venire in soccorso di queste tipologie di analisi grazie alla sua abilità di incorporare in maniera rapida e intelligente i dati, identificando quali siano le relazioni fra di loro e fornendo una visione completa delle dinamiche di vendita. L’analisi dei dati, poi, genera come output strumenti di sintesi e reportage che forniscono informazioni chiare e accessibili sulle vendite e sulle leve che le condizionano. Questi strumenti consentono alle aziende di prendere decisioni strategiche basate su dati concreti, individuare nuove opportunità di mercato e adattare le strategie di vendita per massimizzare il proprio successo."*/
                "body": <span>
  <p>
    Il settore commercio ed e-commerce è caratterizzato da diversi casi sperimentali in cui
    l’intelligenza artificiale è stata adottata per una più approfondita analisi dei fattori di vendita.
  </p>
  <p>
    Partendo dai dati provenienti da diversi ambiti come gli attributi dei prodotti, i prezzi dei
    competitor, la distribuzione e la pubblicità, questa attività risulta fondamentale per capire quali
    siano i fattori che più influenzano le vendite.
  </p>
  <p>
    L’IA può venire in soccorso di queste tipologie di analisi grazie alla sua abilità di incorporare
    in maniera rapida e intelligente i dati, identificando quali siano le relazioni fra di loro e fornendo
    una visione completa delle dinamiche di vendita.
  </p>
  <p>
    L’analisi dei dati genera come output strumenti di sintesi e reportage che forniscono
    informazioni chiare e accessibili sulle vendite e sulle leve che le condizionano.
  </p>
  <p>
    Questi strumenti consentono alle aziende di prendere decisioni strategiche basate su dati concreti,
    individuare nuove opportunità di mercato e adattare le strategie di vendita per massimizzare il proprio
    successo.
  </p>
</span>
            },
            {
                "settore": "Commercio ed e-commerce",
                "label": "Analisi predittiva della domanda di prodotti",
                "body": <span>
  <p>
    Uno dei vantaggi che può portare l’intelligenza artificiale nel settore commercio ed e-commerce
    è rendere le aziende maggiormente in grado di predire la domanda di prodotti in modo accurato
    e informato. L’IA è infatti in grado di prevedere andamenti del mercato che potrebbero non
    essere visibili a occhio nudo, grazie alla capacità dei modelli predittivi di considerare un elevato
    numero di variabili.
  </p>
  <p>
    In particolare, le previsioni sono il risultato dell’analisi dei dati storici di
    vendita e dell’integrazione di questi dati con informazioni provenienti da sistemi aziendali come
    l’ERP. Oltre ai dati interni, l’IA può considerare anche fattori esterni che influenzano la domanda,
    come le tendenze di mercato, le mode e le condizioni economiche, sociali e demografiche
    della popolazione.
  </p>
  <p>
    L’introduzione di questo tipo di sistemi predittivi offre quindi una visione
    più accurata e affidabile della domanda, permettendo alle aziende di pianificare gli acquisti e
    la distribuzione in maniera più efficace, con benefici che possono derivare da una gestione più
    oculata delle scorte, da una minore incidenza delle rotture di stock e dall’offerta di prodotti
    meglio in grado di intercettare i gusti di mercato.
  </p>
</span>
            },
            {
                "settore": "Commercio ed e-commerce",
                "label": "Algoritmi intelligenti per prezzi dinamici",
                "body": <span>
  <p>
    Il concetto dei prezzi dinamici non è nuovo e da sempre è una strategia adottata in molti
    settori, compreso quello di commercio ed e-commerce, per massimizzare i profitti.
    L’avvento dell’intelligenza artificiale però si vede responsabile di un notevole
    efficientamento e miglioramento anche di questa tecnica.
  </p>
  <p>
    Infatti, la scelta del prezzo dipende da numerosi fattori che vengono analizzati
    simultaneamente, come la richiesta di mercato, le disponibilità di magazzino, i prezzi dei
    concorrenti e la stagionalità dei prodotti. L’intelligenza artificiale, in confronto alle tecniche
    tradizionali, ha una maggiore capacità di mettere in relazione fattori molteplici con il
    prezzo, permettendo di definire prezzi che massimizzano realmente i margini, pur rimanendo
    competitivi per i clienti. Questa nuova frontiera nell’ambito dei prezzi non solo favorisce una
    maggiore redditività, ma contribuisce anche a mantenere la competitività delle imprese in un
    mercato in continua evoluzione.
  </p>
</span>
            },
            {
                "settore": "Commercio ed e-commerce",
                "label": "Raccomandazioni personalizzate ",
                "body": <span>
  <p>
    L’esperienza di acquisto è uno dei punti chiave nel settore commercio ed e-commerce.
    L’intelligenza artificiale può risultare un importante alleato nell’arricchire questo processo,
    offrendo raccomandazioni personalizzate ai clienti.
  </p>
  <p>
    Grazie all’analisi del profilo di ogni cliente, inclusi gli acquisti precedenti e le preferenze
    espresse, e delle tendenze di mercato, i sistemi di intelligenza artificiale sono in grado di
    suggerire prodotti che potrebbero interessare al cliente in modo specifico. Al contrario di
    quello che si possa pensare, questo approccio mirato non circoscrive gli interessi del cliente
    a delle sottocategorie, bensì permette ai consumatori di scoprire prodotti che non
    conoscono ma che possono comunque soddisfare le loro esigenze.
  </p>
  <p>
    L’utilizzo dell’intelligenza artificiale per le raccomandazioni personalizzate migliora
    pertanto l’efficacia delle strategie di vendita, aumenta la soddisfazione del cliente e
    favorisce la fidelizzazione tra l’azienda e il consumatore.
  </p>
</span>
            }
        ]
    },
    {
        id: 'metalMec',
        backgroundColor: '#ff9800',
        label: 'Metalmeccanico',
        descriptions: [
            {
                "settore": "Metalmeccanico",
                "label": "Gemelli digitali potenziati da IA",
                "body": <span>
  <p>Nel settore metalmeccanico, una delle applicazioni con un maggiore potenziale è rappresentata dai gemelli digitali basati su intelligenza artificiale per replicare virtualmente prodotti e processi. Il beneficio deriva dalla loro abilità di simulare il comportamento di prodotti – ad esempio permettendo di simulare la resistenza a sforzi di una componente – e dei processi – come l’organizzazione dell’assemblaggio.</p>
  <p>La replica digitale consente alle aziende del settore di testare i loro processi e prodotti accuratamente e senza la necessità di costose prove fisiche, ma con simulazioni virtuali con costi marginali molto contenuti. I vantaggi ritrovati nei casi sperimentali non riguardano solo l’analisi delle prestazioni e del comportamento dei prodotti finiti, ma anche maggiore facilità nell’identificare potenziali miglioramenti o problemi prima ancora che si verifichino fisicamente.</p>
  <p>L’utilizzo dei gemelli digitali nell’ambito della lavorazione dei metalli offre, quindi, una leva per lo sviluppo di un vantaggio competitivo significativo, grazie all’ottimizzazione dei processi e al conseguente miglioramento della qualità e riduzione dei costi. L’introduzione di applicazioni di IA in questa specifica sottofase genera, quindi, diversi benefici economici. In contesti sperimentali, infatti, si è visto come possa portare a una riduzione fino al 5% del costo dei servizi.</p>
</span>
            },
            {
                "settore": "Metalmeccanico",
                "label": "Individuazione automatica dei difetti di verniciatura",
                "body": <span>
  <p>L’individuazione automatica dei difetti di verniciatura sta diventando una pratica sempre più diffusa, specialmente nella fase di controllo qualità. I sistemi di riconoscimento di immagini basati sull’intelligenza artificiale permettono agli operatori di ottenere un controllo qualità direttamente all’interno del processo di verniciatura. Infatti, questi sistemi intelligenti analizzano le immagini delle superfici verniciate, identificando con precisione imperfezioni come schizzi, bolle, crepe o irregolarità.</p>
  <p>Grazie all’alto livello di affidabilità dell’elaborazione delle immagini, è quindi possibile avviare tempestivamente le necessarie azioni correttive, permettendo anche di automatizzare e ottimizzare il processo, riducendo difetti e migliorando la soddisfazione del cliente finale. Diminuendo gli scarti e la portata delle rilavorazioni, questi risultati possono portare, a seconda dei casi, una riduzione dei costi delle materie prime e delle lavorazioni che può arrivare fino al 3-5%.</p>
</span>

            },
            {
                "settore": "Metalmeccanico",
                "label": "Manutenzione predittiva",
                "body": <span>
  <p>La manutenzione predittiva supportata da algoritmi di intelligenza artificiale offre un’importante leva per la gestione degli asset nel settore metalmeccanico. Grazie all’utilizzo di sistemi integrati di IA, questo approccio consente di prevedere in anticipo quando i macchinari potrebbero guastarsi o richiedere manutenzione, consentendo un intervento tempestivo e proattivo.</p>
  <p>Applicando tecnologie di riconoscimento immagine e suono ai macchinari, un operatore può raccogliere dati che vengono analizzati in tempo reale da sistemi predittivi per individuare segnali di possibili guasti o malfunzionamenti imminenti. L’operatore che riceve questi risultati dai sistemi di IA può pianificare di conseguenza in modo strategico le attività di manutenzione, riducendo i tempi di inattività e il numero di guasti che possono portare maggiori costi all’azienda.</p>
  <p>La manutenzione predittiva rappresenta una significativa evoluzione nel settore della lavorazione dei metalli, offrendo un vantaggio competitivo attraverso la riduzione dei costi di manutenzione, l’aumento della produttività e il miglioramento complessivo delle prestazioni dell’impianto. In termini economici, alcune stime evidenziano un potenziale impatto della manutenzione predittiva nell’ordine del 5-10% dei tempi di fermo macchina.</p>
</span>

            },
            {
                "settore": "Metalmeccanico",
                "label": "Routing flessibile, programmazione e bilanciamento del carico",
                "body": <span>
  <p>Nel settore metalmeccanico, l’integrazione di sistemi automatici per il trasporto dei componenti lungo la catena produttiva sta avendo un impatto significativo sulle operazioni. I numerosi benefici sono il risultato di questi sistemi basati su intelligenza artificiale che permettono di prendere decisioni informate, rendendo possibile ottimizzare lo scheduling delle lavorazioni, migliorare il bilanciamento del carico e ridurre i tempi complessivi.</p>
  <p>Infatti, l’abilità di questi sistemi intelligenti è di riuscire ad adattarsi alle esigenze produttive in continuo cambiamento, grazie al flusso di dati che ricevono dalle attività di trasformazione. Questo permette un routing flessibile dei componenti e così un flusso potenzialmente più efficiente lungo la catena di produzione, con benefici che sono stati stimati in una riduzione dei lead time fino al 10% e degli scarti del 5-8%.</p>
</span>

            },
        ]
    },
    {
        id: 'metalSid',
        backgroundColor: '#9c27b0',
        label: 'Metalsiderurgico',
        descriptions: [
            {
                "settore": "Metalsiderurgico",
                "label": "Robotica e automazione",
                "body": <span>
                  <p>La combinazione di robotica, automazione e intelligenza artificiale ha il potenziale di impattare profondamente il settore metalsiderurgico. Un’applicazione suggerita dalle ricerche del settore è quella dei robot specializzati in grado di eseguire in maniera automatizzata una vasta gamma di compiti. Essere supportati da intelligenza artificiale permette ai robot di apprendere per rinforzo, rendendo loro possibile imparare a eseguire diversi task, con un notevole miglioramento delle prestazioni dato dalla loro capacità di riconoscere e correggere con il tempo eventuali errori e anomalie.</p>
                  <p>Le ricerche suggeriscono, in particolare, l’utilizzo di questi robot per le attività ad alto rischio per gli operatori umani, come la manipolazione di carichi pesanti o l’esecuzione di operazioni in ambienti ostili. L’introduzione di applicazioni di IA può portare quindi diversi benefici economici. In contesti sperimentali, infatti, si è visto come possa portare a una riduzione dei costi per la gestione di errori e anomalie nell’ordine anche del 5-8% dei costi produttivi totali.</p>
                </span>
            },
            {
                "settore": "Metalsiderurgico",
                "label": "Gestione predittiva dell’elasticità nelle forniture",
                "body": <span>
                  <p>La gestione dell’elasticità nelle forniture rappresenta un aspetto cruciale nel settore metalsiderurgico. Attraverso lo studio delle oscillazioni dei prezzi, è possibile ottimizzare l’acquisto di materia prima, che può essere rottame o materiale nobile, al prezzo più vantaggioso possibile.</p>
                  <p>L’utilizzo di tecnologie di l’intelligenza artificiale permette di analizzare e prevedere i movimenti dei prezzi nel mercato delle materie prime in tempo reale, identificare opportunità di acquisto vantaggiose e prendere decisioni basate su dati accurati, consentendo alle aziende di pianificare in modo strategico gli acquisti e di adeguare la produzione di conseguenza.</p>
                  <p>Questo approccio consente alle aziende metalsiderurgiche di ridurre i costi di approvvigionamento, massimizzando al contempo l’utilizzo delle risorse e ottimizzando la produzione. Questi risultati portano dei vantaggi economici significativi grazie all’adattamento dell’approvvigionamento rispetto all’offerta, stimabili anche in un 3-5% dei costi delle materie prime.</p>
                </span>
            },
            {
                "settore": "Metalsiderurgico",
                "label": "Manutenzione predittiva",
                "body": <span>
                  <p>La manutenzione predittiva riveste un’importanza particolare nel settore metalsiderurgico. Le apparecchiature e i macchinari utilizzati in questo settore sono spesso sottoposti ad ambienti estremi e sollecitazioni intense che favoriscono guasti e malfunzionamenti imprevisti.</p>
                  <p>L’opportunità proposta dall’intelligenza artificiale è quella di utilizzare tecnologie di riconoscimento immagine e suono che, integrate con capacità predittiva, permettono l’individuazione di segnali che evidenziano la necessità di intraprendere azioni di manutenzione. Questo approccio proattivo consente di preservare l’integrità delle attrezzature, migliorare la sicurezza sul lavoro e aumentare l’efficienza complessiva della produzione.</p>
                  <p>In termini economici, la manutenzione predittiva può abbattere anche del 20-30% i costi di manutenzione, senza considerare la riduzione dei fermi di impianto, che soprattutto nelle produzioni di processo ha dei costi estremamente significativi (es. ciclo di raffreddamento e riattivazione degli altiforni).</p>
                </span>
            },
            {
                "settore": "Metalsiderurgico",
                "label": "Ottimizzazione energetica",
                "body": <span>
                  <p>Il momento storico ha reso l’ottimizzazione energetica un aspetto fondamentale per le aziende di diversi settori, e in particolare per il settore metalsiderurgico. Una possibile leva è l’utilizzo di applicazioni di intelligenza artificiale. Queste tecnologie analizzano i dati sul consumo energetico, identificano le aree di inefficienza e propongono soluzioni mirate a ridurre gli sprechi.</p>
                  <p>Attraverso l’ottimizzazione energetica, le aziende del settore metalsiderurgico possono ottenere una riduzione significativa dei costi operativi, migliorare la propria competitività e contribuire a una maggiore sostenibilità ambientale. Nella pratica, questo tipo di applicazione di intelligenza artificiale può portare, a seconda delle condizioni di partenza, a riduzioni anche del 5-10% dei costi dell’energia.</p>
                </span>
            },
        ]
    },
    {
        id: 'mobileArr',
        backgroundColor: '#009688',
        label: 'Mobile e Arredo',
        descriptions: [
            {
                "settore": "Mobile e Arredo",
                "label": "Generazione assistita di design",
                "body": <span>
  <p>
    Nel mondo del mobile e arredo, l’intelligenza artificiale assume un ruolo cruciale nella
    generazione di nuovi design. Tecnologie di IA generativa sono in grado di supportare nella
    creazione di concept innovativi da zero oppure prendendo spunto da elementi già esistenti.
  </p>
  <p>
    Attraverso l’elaborazione di dati e l’apprendimento automatico, l’intelligenza artificiale
    realizza prototipi concreti per la realizzazione di prodotti destinati al commercio. Modelli
    tridimensionali e virtuali prendono forma grazie a questi sistemi, fornendo ai designer una
    visione tangibile delle loro creazioni.
  </p>
  <p>
    Questo processo, che sfrutta la potenza di apprendimento e di interpretazione
    dell’intelligenza artificiale, apre nuove frontiere creative nel settore mobile
    e arredo, consentendo l’esplorazione di forme, materiali e stili con maggiore efficienza e
    originalità.
  </p>
</span>
            },
            {
                "settore": "Mobile e Arredo",
                "label": "Ottimizzazione delle operazioni di segheria",
                "body": <span>
  <p>
    Nel settore mobile e arredo, le operazioni di segheria sono il fulcro della trasformazione
    da materia prima a semilavorato e prodotto finito. Queste attività possono subire netti
    miglioramenti grazie all’applicazione di sistemi di intelligenza artificiale.
  </p>
  <p>
    Ad esempio, la combinazione di IA generativa e riconoscimento di immagini permette di
    ottimizzare le attività di taglio del legname in modo considerevole. Gli algoritmi analizzano
    le caratteristiche e i difetti della specifica tipologia di legno, generando piani di taglio
    personalizzati e ottimali.
  </p>
  <p>
    Le analisi delle condizioni vengono eseguite dalle tecnologie di IA che a loro volta
    forniscono tutte le informazioni necessarie ai professionisti della linea per prendere le
    decisioni operative. Questo permette alle linee di produzione di contare su un supporto
    affidabile e innovativo per creare prodotti di alta qualità in modo più rapido ed
    economicamente vantaggioso.
  </p>
  <p>
    Per quanto sia un parametro fortemente dipendente dal contesto specifico applicativo,
    l’efficientamento indotto può incidere in misura significativa sui costi di queste fasi
    produttive (idealmente, dal 3 al 10% a seconda dei casi).
  </p>
</span>
            },
            {
                "settore": "Mobile e Arredo",
                "label": "Realtà aumentata e virtuale (AR/VR) potenziata da IA ",
                "body": <span>
  <p>
    Il trend della realtà aumentata (AR) e virtuale (VR) è di fondamentale importanza anche nel
    settore mobile e arredo. Grazie a queste soluzioni, è ora possibile visualizzare i prodotti in
    modo tridimensionale e proiettarli all’interno dello spazio fisico desiderato con un livello di
    precisione molto elevato.
  </p>
  <p>
    Supportare queste tecnologie con sistemi di intelligenza artificiale ha permesso un’autonomia
    nella gestione dei contenuti e un adattamento intelligente dei modelli ai diversi spazi.
    Questa combinazione di AR/VR e intelligenza artificiale consente ai consumatori di esplorare
    e sperimentare diverse configurazioni e posizionamenti dei mobili e degli elementi d’arredo, o
    in maniera totalmente virtuale, o vedendo la proiezione dell’arredamento nella propria casa.
  </p>
  <p>
    Questa modalità risulta quindi più coinvolgente e fornisce un’idea più realistica al consumatore,
    permettendogli di prendere decisioni d’acquisto più informate.
  </p>
</span>
            },
            {
                "settore": "Mobile e Arredo",
                "label": "Sistemi di controllo di IA per macchinari",
                "body": <span>
  <p>
    Le novità portate nel settore dell’arredamento dall’intelligenza artificiale impattano
    significativamente anche i singoli macchinari in fase di produzione. I robot specializzati nella
    lavorazione del legno e di altri materiali per l’arredo vengono dotati di sistemi di intelligenza
    artificiale che li rendono versatili su più attività.
  </p>
  <p>
    Grazie a queste dotazioni intelligenti, i macchinari possono eseguire task come il taglio, la
    fresatura, la levigatura e altri ancora, in maniera totalmente automatizzata e indipendente.
    Questi macchinari intelligenti costituiscono un’importante spinta per l’industria dell’arredamento,
    in quanto consentono di aumentare l’efficienza e la precisione delle operazioni.
  </p>
  <p>
    È da segnalare anche un vantaggio importante in termini di sicurezza per gli operatori umani,
    i cui compiti più pericolosi e pesanti possono essere assegnati a questi macchinari potenziati
    dall’intelligenza artificiale.
  </p>
</span>
            },
        ]
    },
    {
        id: 'modaAbb',
        backgroundColor: '#4caf50',
        label: 'Moda e Abbigliamento',
        descriptions: [
            {
                "settore": "Moda e Abbigliamento",
                "label": "Generazione assistita di design",
                "body": <span>
  <p>L’applicazione di intelligenza artificiale generativa nelle fasi di design ha il potenziale di rivoluzionare alcuni aspetti del settore moda e abbigliamento. A partire da semplici schizzi, mood board e descrizioni testuali, queste applicazioni utilizzano algoritmi avanzati per creare modelli 3D realistici e modificabili.</p>
  <p>I disegni a mano libera vengono interpretati e trasformati in rappresentazioni digitali dettagliate, mentre i mood board vengono analizzati per comprendere lo stile desiderato. L’IA consente di esplorare una vasta gamma di idee di prodotto in modo rapido ed efficiente, riducendo i tempi di sviluppo e facilitando il processo creativo.</p>
  <p>Grazie a questa innovazione, la creazione di nuovi prodotti diventa ancora di più un processo collaborativo e coinvolgente, in cui l’intelligenza artificiale amplifica la creatività dei progettisti supportandoli nelle attività più operative e lasciando loro il tempo di concentrarsi sulle attività che richiedono maggiormente la loro fantasia.</p>
  <p>L’introduzione di applicazioni di IA in questa specifica sottofase può incidere anche significativamente sul conto economico, sia tagliando i costi di sviluppo (si ipotizza anche del 3-5%) sia potenziando la capacità di copertura del mercato.</p>
</span>

            },
            {
                "settore": "Moda e Abbigliamento",
                "label": "Gestione intelligente della scorta di prodotti finiti",
                "body": <span>
  <p>Nel settore moda e abbigliamento, l’utilizzo dell’intelligenza artificiale per la gestione della scorta dei prodotti finiti rappresenta un importante strumento per ridurre l’impatto di problemi come eccessi di stock o mancanza di prodotti.</p>
  <p>Grazie al monitoraggio del magazzino a livello centrale, dell’e-commerce e dei punti vendita fisici, sistemi di analisi predittiva supportati da IA analizzano in tempo reale i dati sulle vendite e le previsioni di domanda. Questo permette di prendere decisioni informate sul riapprovvigionamento dei prodotti, garantendo che siano disponibili nel momento giusto senza accumulare stock invenduti o rischiare una rottura.</p>
  <p>L’integrazione dell’intelligenza artificiale nella gestione delle scorte ha pertanto il potenziale di facilitare una pianificazione più efficiente, ridurre i costi di stoccaggio e assicurare che i clienti trovino sempre i prodotti desiderati. In termini economici, una gestione ottimizzata delle scorte può ridurre anche del 5% il capitale circolante.</p>
</span>

            },
            {
                "settore": "Moda e Abbigliamento",
                "label": "AI Merchandising",
                "body": <span>
  <p>Nel settore moda e abbigliamento, i sistemi di supporto basati sull’intelligenza artificiale offrono un vantaggio significativo per le attività di merchandising. Applicazioni di questo tipo analizzano i dati relativi alle prestazioni passate, alle condizioni di mercato, alle tendenze e ai fattori esterni come la posizione geografica, il clima e le caratteristiche demografiche della popolazione.</p>
  <p>Partendo da queste informazioni, questi sistemi sono in grado di mettere in correlazione le diverse variabili analizzate, elaborando modelli complessi che permettono di comprendere al meglio le preferenze del cliente, fornendo raccomandazioni di merchandising in tempo reale. Ciò consente ai rivenditori di ottimizzare la disposizione dei prodotti nei punti vendita fisici, la selezione e la presentazione dei prodotti online, e persino la personalizzazione dell’esperienza d’acquisto.</p>
  <p>L’IA nel merchandising pertanto supporta la presa di decisioni strategiche, migliorando la redditività, l’efficienza e la soddisfazione dei clienti. Questi risultati possono apportare, a seconda dei contesti, dei vantaggi economici significativi grazie all’adattamento dell’offerta rispetto ai consumatori, anche nell’ordine di unità percentuali del fatturato.</p>
</span>
            },
            {
                "settore": "Moda e Abbigliamento",
                "label": "Suggerimenti personalizzati",
                "body": <span>
  <p>L’applicazione di suggerimenti personalizzati utilizza l’analisi dei dati dei clienti per offrire un’esperienza di shopping su misura. Attraverso l’analisi degli attributi, come lo stile preferito, il colore, la taglia e altre informazioni relative agli acquisti passati e alla cronologia di navigazione, l’applicazione utilizza l’intelligenza artificiale per fornire consigli personalizzati ai clienti.</p>
  <p>Questi suggerimenti includono una selezione accurata di prodotti presenti nel catalogo dell’azienda, abbinamenti di outfit e combinazioni di prodotti che si adattano ai gusti e alle preferenze individuali dei clienti. L’intelligenza artificiale, grazie quindi all’elaborazione dei dati e all’apprendimento automatico, è in grado di comprendere e anticipare le esigenze della clientela offrendo raccomandazioni pertinenti che migliorano l’esperienza d’acquisto e promuovono la soddisfazione del cliente.</p>
  <p>Con l’uso di questi suggerimenti personalizzati, le aziende nel settore della moda possono creare un legame più forte con i propri clienti, aumentare la fidelizzazione e favorire l’incremento delle vendite. Questi risultati possono apportare, a seconda dei contesti, dei vantaggi economici significativi grazie all’adattamento dell’offerta rispetto ai consumatori, anche nell’ordine di unità percentuali del fatturato.</p>
</span>
            },
        ]
    },
    {
        id: 'turismo',
        backgroundColor: '#e91e63',
        label: 'Turismo',
        descriptions: [
            {
                "settore": "Turismo",
                "label": "Chatbot per prenotazioni e servizio clienti per le compagnie di viaggio",
                "body": <span>
  <p>Negli ultimi anni le compagnie di viaggio hanno cominciato ad adottare chatbot intelligenti per semplificare la gestione delle richieste, delle prenotazioni e dell’assistenza ai clienti. Questi assistenti virtuali sono supportati da sistemi di riconoscimento di voce e suono, che permettono loro di comprendere le domande dei clienti. Integrando questi sistemi con capacità di IA generativa, i chatbot possono anche rispondere alle domande dei clienti in modo più rapido ed efficace, fornendo informazioni su voli, alloggi, servizi aggiuntivi e molto altro.</p>
  <p>Chatbot di questo tipo possono essere utilizzati in tutte le fasi della prenotazione, offrendo un’esperienza il più possibile fluida e intuitiva, riducendo la necessità di interagire con un operatore umano qualora non disponibile o impegnato in altre attività dal più alto valore aggiunto. Il loro utilizzo può essere particolarmente utile in casi di necessità di assistenza immediata per problemi durante il viaggio, come ritardi o modifiche delle prenotazioni, senza dover attendere la disponibilità di un operatore.</p>
  <p>Questo servizio clienti 24/7 offerto dai chatbot intelligenti consente alle compagnie di viaggio di migliorare l’efficienza operativa e garantire un’esperienza di viaggio senza soluzione di continuità per i propri clienti. Questi risultati possono portare vantaggi economici significativi grazie all’efficientamento del servizio e all’ottimizzazione della capacità di vendita, anche nella misura del 5-8% dei ricavi.</p>
</span>
            },
            {
                "settore": "Turismo",
                "label": "Algoritmi per consigli di viaggio personalizzati",
                "body": <span>
  <p>Le imprese che operano nel settore del turismo possono utilizzare sistemi basati sull’intelligenza artificiale per offrire consigli di viaggio più personalizzati. Queste soluzioni analizzano una grande quantità di dati quali le preferenze individuali, la cronologia dei viaggi e altri dati relativi all’offerta del settore al fine di offrire suggerimenti su misura per destinazioni, attività e alloggi. Grazie a questa tecnologia avanzata, i viaggiatori possono ottenere consigli mirati su dove andare, cosa fare e dove alloggiare, tenendo conto dei loro gusti personali e delle esperienze passate o di quelle che si desidera provare. Questi sistemi non solo semplificano la pianificazione dei viaggi, ma permettono anche di scoprire nuove destinazioni ed esperienze uniche che altrimenti potrebbero sfuggire all’attenzione.</p>
</span>
            },
            {
                "settore": "Turismo",
                "label": "Prezzi dinamici per gli hotel supportati da IA",
                "body": <span>
  <p>
    Grazie all’analisi dei dati, come i tassi di occupazione, la stagionalità e i programmi di eventi,
    modelli predittivi supportati da IA possono aiutare gli imprenditori a regolare i prezzi degli hotel in tempo reale.
    Così, le strutture alberghiere possono adattare i propri prezzi in base alla domanda e all’offerta correnti,
    ottimizzando l’occupazione delle camere e la massimizzazione del profitto.
  </p>
  <p>
    Infatti, i sistemi di IA predittivi hanno, rispetto ai sistemi tradizionali, una maggiore capacità di
    tenere conto di diversi fattori per la determinazione dei prezzi,
    il che gli permette di identificare al meglio l’influenza di questi fattori sull’attrattività dell’offerta di un hotel.
    Questa maggiore flessibilità e accuratezza nel definire le tariffe per l’alloggio si traduce in vantaggi sia per gli albergatori
    sia per i viaggiatori.
  </p>
  <p>
    I prezzi dinamici consentono agli hotel di rimanere competitivi,
    adattandosi rapidamente ai cambiamenti nel mercato e sfruttando al massimo la capacità delle proprie strutture.
    Per i viaggiatori, ciò significa avere la possibilità di trovare offerte vantaggiose e tariffe che rispecchiano al meglio i loro requisiti.
  </p>
  <p>
    In definitiva, l’utilizzo dell’IA per i prezzi dinamici negli hotel rappresenta un beneficio per gli albergatori e per i viaggiatori,
    offrendo tariffe competitive e una gestione più efficiente delle risorse alberghiere.
    Nella pratica, si è dimostrato come questo tipo di applicazione di intelligenza artificiale possa portare a un aumento del 3-5% dei ricavi
    grazie all’ottimizzazione delle curve di prezzo legate alla saturazione.
  </p>
</span>
            },
            {
                "settore": "Turismo",
                "label": "Riconoscimento automatico di luoghi di interesse",
                "body": <span>
  <p>
    L’intelligenza artificiale permette di sfruttare il riconoscimento dei luoghi di interesse per offrire consigli basati sulla posizione agli utenti.
    Questa tecnologia sfrutta il riconoscimento di immagini per analizzare in maniera dinamica e automatica i luoghi visitati e identificare ristoranti,
    attrazioni e altri punti di interesse nelle vicinanze.
  </p>
  <p>
    Grazie a questi suggerimenti puntuali, i viaggiatori possono scoprire facilmente luoghi unici da visitare e sperimentare durante i loro viaggi.
    Che si tratti di un ristorante caratteristico, di una galleria d’arte o di un sito storico,
    l’intelligenza artificiale può offrire consigli accurati in base alla posizione dell’utente, arricchendo così l’esperienza di viaggio.
  </p>
  <p>
    Servizi di questo tipo vengono in genere offerti da aziende del settore tecnologico,
    rendendo ancora più rilevante per le imprese che offrono attività e intrattenimento nel settore del turismo investire sulla propria presenza
    e reperibilità online, che rappresenta un punto di contatto con il cliente sempre più significativo.
  </p>
  <p>
    Questa tecnologia rende la scoperta di nuovi luoghi di interesse ancora più accessibile e conveniente,
    offrendo ai viaggiatori la possibilità di esplorare e vivere appieno il loro ambiente circostante.
  </p>
</span>
            },
        ]
    },
]

const segmentsMap = {}
buoniPropositi.forEach((obj) => {
    segmentsMap[obj.id] = obj
})

const googleColors = [
    "#EA4335",
    "#FABC05",
    "#5187BC",
    "#33A852",
    "#7fa6e8",
    "#fab6b0",
    "#8db2a8",
    "#7fa6e8",
]